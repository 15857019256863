<template>
  <div class="w-full overflow-hidden">
    <button @click="goBack()"
      class="w-auto mt-5 font-semibold text-center text-gray-700 rounded-full focus:outline-none dark:text-gray-400">
      &larr; go back
    </button>
    <div class="flex flex-col space-x-4 xl:flex-row">
      <div
        class="flex flex-col w-full h-full p-5 my-5 space-y-2 bg-white border border-gray-200 shadow-md sm:max-w-xs rounded-xl dark:border-gray-500 dark:bg-gray-700">
        <div class="flex items-center space-x-2">
          <p class="text-lg font-bold text-gray-700 dark:text-gray-200">
            {{ customer.name }}
          </p>
          <div :class="
            customer.verified
              ? 'text-primary-light dark:text-indigo-500'
              : 'text-gray-500 dark:text-gray-400'
          ">
            <svg v-if="customer.verified" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              class="w-5 h-5">
              <path fill-rule="evenodd"
                d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd" />
            </svg>
            <svg v-else class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        </div>
        <div>
          <p class="font-medium text-gray-500 dark:text-gray-400">
            {{ customer.mobile }}
          </p>
        </div>
        <div class="flex items-center justify-between">
          <!-- <p class="font-mono text-green-800 dark:text-green-400">
            Paid ₹{{ customer.total_paid }}
          </p> -->
          <!-- <p class="font-mono text-red-800 dark:text-red-400">
            Pending ₹{{ customer.total_pending ? customer.total_pending : 0.0 }}
          </p> -->
        </div>
      </div>
      <div class="w-full my-5 overflow-hidden border border-gray-300 rounded-xl dark:border-gray-600">
        <div class="w-full overflow-x-auto">
          <table class="w-full whitespace-no-wrap">
            <thead>
              <tr
                class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase bg-gray-300 border-b dark:border-gray-700 dark:text-gray-400 dark:bg-gray-700">
                <th class="px-4 py-3">Product</th>
                <th class="px-4 py-3">Payment</th>
                <th class="px-4 py-3">Status</th>
                <th class="px-4 py-3">Created on</th>
                <th v-if="user.level > 10" class="px-4 py-3">Action</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800" v-if="orders.length > 0">
              <tr v-for="order in orders" :key="order.nano_id" class="text-gray-700 dark:text-gray-400">
                <td class="px-4 py-3">
                  <div class="flex flex-col space-y-1">
                    <p class="font-semibold">{{ order.product.name }}</p>
                    <p class="font-mono font-medium">₹{{ order.amount }}</p>
                    <p class="font-medium">{{ order.cafe.name }}</p>
                  </div>
                </td>
                <td class="px-4 py-3">
                  <div class="flex flex-col justify-center">
                    <p class="font-mono text-sm font-medium text-green-700 dark:text-green-500">
                      ₹{{ order.paid }}
                    </p>
                    <p class="font-mono text-sm font-medium text-red-800 dark:text-red-400">
                      ₹{{ order.pending }}
                    </p>
                  </div>
                </td>

                <td class="px-4 py-3 capitalize text-medium">
                  <span class="px-2 py-1 font-semibold leading-tight capitalize rounded-full" :class="
                    order.status === 'paid'
                      ? 'text-green-700 bg-green-100 dark:bg-green-700 dark:text-green-100'
                      : 'text-red-700 bg-red-100 dark:bg-red-700 dark:text-red-100'
                  ">
                    {{ order.status }}
                  </span>
                </td>

                <td class="px-4 py-3 text-medium">
                  <p>{{ order.created_at | humanDate }}</p>
                </td>
                <td v-if="user.level > 10" class="px-4 py-3 text-medium">
                  <button v-if="user.level > 10"
                    class="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 rounded-lg text-primary-light dark:text-gray-400 focus:outline-none "
                    aria-label="Delete" @click="deleteOrder(order.nano_id)">
                    <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path fill-rule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clip-rule="evenodd"></path>
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase bg-gray-300 border-t dark:border-gray-700 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-700">
          <span class="flex items-center col-span-3">
            Showing
            {{
              pagination.meta
                ? `${pagination.meta.from}-${pagination.meta.to} of ${pagination.meta.total}`
                : null
            }}
          </span>
          <span class="col-span-2"></span>
          <!-- Pagination -->
          <span class="flex col-span-4 mt-2 sm:mt-auto sm:justify-end">
            <nav aria-label="Table navigation">
              <ul class="inline-flex items-center">
                <li>
                  <button :class="
                    pagination.links && pagination.links.prev
                      ? 'text-gray-600 dark:text-gray-400'
                      : 'text-gray-400 dark:text-gray-500'
                  " :disabled="pagination.links && !pagination.links.prev" class="px-2 py-1 rounded-md rounded-l-lg focus:outline-none"
                    aria-label="Previous" @click="loadOrders(pagination.meta.current_page - 1)">
                    <svg class="w-4 h-4 fill-current" aria-hidden="true" viewBox="0 0 20 20">
                      <path
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd" fill-rule="evenodd"></path>
                    </svg>
                  </button>
                </li>
                <li>
                  <button :class="
                    pagination.links && pagination.links.next
                      ? 'text-gray-600 dark:text-gray-400'
                      : 'text-gray-400 dark:text-gray-500'
                  " :disabled="pagination.links && !pagination.links.next"
                    class="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-indigo"
                    aria-label="Next" @click="loadOrders(pagination.meta.current_page + 1)">
                    <svg class="w-4 h-4 fill-current" aria-hidden="true" viewBox="0 0 20 20">
                      <path
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd" fill-rule="evenodd"></path>
                    </svg>
                  </button>
                </li>
              </ul>
            </nav>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import isEmpty from "ramda/es/isEmpty";
export default {
  name: "CustomerDetail",
  data() {
    return {};
  },
  mounted() {
    if (isEmpty(this.customer)) {
      this.loadcustomer();
    }
  },
  computed: {
    ...mapGetters({
      customer: ["customer/getCustomer"],
      orders: ["customer/getOrders"],
      pagination: ["customer/getOrderPagination"],
      user: ["auth/getAuthUser"],
    }),
  },
  beforeDestroy() {
    this.$store.dispatch("customer/clearDetailState");
  },
  methods: {
    loadcustomer() {
      this.$store.dispatch("customer/loadCustomer", {
        nano_id: this.$route.params.nano_id,
      });
    },
    loadOrders(page = "", status = "") {
      this.$store.dispatch("customer/loadOrders", {
        customer_id: this.customer.id,
        page,
        status,
      });
    },
    deleteOrder(nano_id) {
      this.$store.dispatch("customer/deleteOrder", { nano_id });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    customer: {
      immediate: true,
      handler(newValue) {
        if (!isEmpty(newValue)) {
          this.loadOrders();
        }
      },
    },
  },
  filters: {
    humanDate(date) {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      const today = new Date(date);
      return today.toLocaleDateString("en-IN", options);
    },
  },
};
</script>

<style>

</style>
